"use client";

import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Message from '../components/message';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // EmailJS configuration.
    const serviceId = 'service_nzdr0o7';
    const templateId = 'template_8wgsdf7';
    const userId = 'qLSrzBvGq0u1m1Urt';

    // Send email using EmailJS
    emailjs.send(serviceId, templateId, {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    }, userId)
      .then(() => {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch(() => {
        setSubmitStatus('error');
      });
  };

  const bodyFont = "font-sans font-semibold";

  return (
    <div id="contact" className="bg-white py-8 pb-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg shadow-xl">
          <div className="flex flex-col md:flex-row">
            {/* Left side: Text and description */}
            <div className="bg-white p-8 md:w-1/2 flex flex-col justify-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact Us</h2>
              <div className="mt-6 flex items-center space-x-4">
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <a href="tel:02102369008" className={`${bodyFont} text-gray-600 hover:text-green-600 transition-colors duration-300`}>
                  021 023 69008
                </a>
              </div> 
              <div className="mt-2 flex items-center space-x-4">
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <a href="mailto:daniel@dbpropertyservices.co.nz" className={`${bodyFont} text-gray-600 hover:text-green-600 transition-colors duration-300`}>
                  daniel@dbpropertyservices.co.nz
                </a>
              </div>
              <p className={`${bodyFont} mt-4 text-lg leading-8 text-gray-600`}>
                We&apos;d love to hear from you. Please fill out the form and we&apos;ll get back to you as soon as possible.
              </p>
            </div>

            {/* Right side: Contact form */}
            <div className="bg-gray-100 p-8 md:w-1/2">
              {submitStatus === 'success' && (
                <Message 
                  type="success"
                  message="Your message has been sent successfully. We'll get back to you soon!"
                  onClose={() => setSubmitStatus('idle')}
                />
              )}
              {submitStatus === 'error' && (
                <Message 
                  type="error"
                  message="There was an error sending your message. Please try again later."
                  onClose={() => setSubmitStatus('idle')}
                />
              )}
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mt-2 block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-2 block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    className="mt-2 block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    required
                  ></textarea>
                </div>
                <div>
                  <button
                    aria-label="Send message"
                    type="submit"
                    className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
